import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import omit from 'lodash/omit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Dropzone from 'react-dropzone';

import {
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Stack,
} from 'react-bootstrap';

import api from 'api';
import useUser from 'hooks/useUser';
import useGoBack from 'hooks/useGoBack';
import useFeedback from 'hooks/useFeedback';
import { DataUploadOptions } from 'utils/enums';

import SectionWrapper from 'components/layout/SectionWrapper';
import FontAwesomeIcon from 'components/elements/FontAwesomeIcon';
import colors from 'resources/styles/colors';

import { allowedMigrationNames, allowedExt } from './utils';

const schema = yup.object({
  type: yup.string().required('Required'),
});

const UploadAddPage = () => {
  const { userBurnCenter } = useUser();
  const { goBack } = useGoBack();
  const [uploadProgress, setUploadProgress] = React.useState({ init: 0 });
  const [uploadFiles, setUploadFiles] = React.useState([]);

  const {
    handleSubmit,
    register,
    formState,
    setError: setFormError,
    watch,
  } = useForm({
    defaultValues: {
      type: '',
    },
    resolver: yupResolver(schema),
  });
  const { setSuccess, setError } = useFeedback();

  const currentType = watch('type');

  const isDataMigration = React.useMemo(
    () => currentType === 'DataMigration',
    [currentType],
  );

  const fileValidator = React.useCallback(
    (file) => {
      if (!isDataMigration || !file?.name) return null;

      const filename = file.name.split('.')[0];
      if (!allowedMigrationNames.includes(filename)) {
        return {
          code: 'not-allowed-migration',
          message: 'Name not allowed for Data Migration',
        };
      }
      return null;
    },
    [isDataMigration],
  );

  const uploadPercent = React.useMemo(() => {
    const fileUploads = Object.values(omit(uploadProgress, ['init']));
    if (!fileUploads.length) return uploadProgress.init;
    const totalUpload = fileUploads.reduce((res, val) => res + val, 0);
    const averageUpload = totalUpload / fileUploads.length;

    return (
      uploadProgress.init + averageUpload * (1 - uploadProgress.init / 100)
    );
  }, [uploadProgress]);

  const onSubmit = async (formValues) => {
    setUploadProgress({ init: 0 });
    if (!uploadFiles?.length) {
      setFormError('fileInput', {
        message: 'Required',
      });
      return;
    }

    if (!isDataMigration && uploadFiles.length > 1) {
      setFormError('fileInput', {
        message: 'Only one file is allowed',
      });
      return;
    }

    try {
      const {
        data: { files },
      } = await api.postUpload({
        ...formValues,
        files: uploadFiles.map((file) => ({
          filename: file.name,
          contentType: file.type || 'application/octet-stream',
        })),
      });

      setUploadProgress({ init: 20 });

      const uploadPromises = files.map((file, index) => {
        const submitFile = uploadFiles[index];

        return axios.put(file.uploadUrl, submitFile, {
          headers: {
            'Content-Type': file.contentType,
            ...file.metadata,
          },
          onUploadProgress: (progressEvent) => {
            setUploadProgress((currentUpload) => ({
              ...currentUpload,
              [submitFile.name]: Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              ),
            }));
          },
        });
      });

      await Promise.all(uploadPromises);

      setSuccess('File uploaded successfully');
      goBack();
    } catch (error) {
      setError('Unexpected error uploading file');
    }
  };

  return (
    <SectionWrapper title="Upload File">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container className="p-5 bg-light position-relative" fluid>
          <Row className="mb-4">
            <Col xs={12} lg={2}>
              <Form.Label>
                <b>Burn Center:</b>
              </Form.Label>
            </Col>
            <Col xs={12} lg={10}>
              <span>{userBurnCenter?.name}</span>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} lg={2}>
              <Form.Label
                htmlFor="type"
                style={{
                  color: formState?.errors?.type?.message
                    ? colors.red
                    : 'inherit',
                }}
              >
                <b>File type:</b>
                <div>{formState?.errors?.type?.message}</div>
              </Form.Label>
            </Col>
            <Col xs={12} lg={10}>
              <Form.Select
                {...register('type', { required: true })}
                id="type"
                isInvalid={formState?.errors?.type?.message}
              >
                <option value="">- Please select -</option>
                {DataUploadOptions.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} lg={2}>
              <Form.Label htmlFor="description">
                <b>Description:</b>
              </Form.Label>
            </Col>
            <Col xs={12} lg={10}>
              <Form.Control
                {...register('description', { required: true })}
                id="description"
                required
              />
            </Col>
          </Row>
          {currentType && (
            <Row className="mb-4">
              <Col xs={12} lg={2}>
                <Form.Label
                  htmlFor="fileInput"
                  style={{
                    color: formState?.errors?.fileInput?.message
                      ? colors.red
                      : 'inherit',
                  }}
                >
                  <b>File:</b>
                  <div>{formState?.errors?.fileInput?.message}</div>
                </Form.Label>
              </Col>
              <Col xs={12} lg={10}>
                <Dropzone
                  accept={allowedExt}
                  multiple={isDataMigration}
                  onDropAccepted={(acceptedFiles) => {
                    setUploadFiles((lastFiles) => {
                      if (!isDataMigration) return acceptedFiles;

                      return Object.values(acceptedFiles).reduce(
                        (res, file) => (res.some(
                          (f) => f.name === file.name
                              && f.lastModified === file.lastModified
                              && f.size === file.size
                              && f.type === file.type,
                        )
                          ? res
                          : [...res, file]),
                        lastFiles,
                      );
                    });
                  }}
                  validator={fileValidator}
                >
                  {({
                    fileRejections,
                    getRootProps,
                    getInputProps,
                    isFocused,
                    isDragAccept,
                    isDragReject,
                  }) => (
                    <section>
                      <div
                        {...getRootProps({
                          style: {
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '20px',
                            borderWidth: 2,
                            borderRadius: 2,
                            borderColor: '#eeeeee',
                            borderStyle: 'dashed',
                            backgroundColor: '#fafafa',
                            color: '#bdbdbd',
                            outline: 'none',
                            transition: 'border .24s ease-in-out',
                            ...(isFocused ? { borderColor: '#2196f3' } : {}),
                            ...(isDragAccept ? { borderColor: '#00e676' } : {}),
                            ...(isDragReject ? { borderColor: '#ff1744' } : {}),
                          },
                        })}
                      >
                        <input {...getInputProps()} />
                        <p>
                          Drag and drop some files here, or click to select
                          files
                        </p>
                      </div>
                      {!!uploadFiles?.length && (
                        <div>
                          <b>Files to upload:</b>
                          <ul>
                            {uploadFiles.map(({ name }, index) => (
                              <li key={uuid()}>
                                <span>{name}</span>
                                <Button
                                  className="mx-2"
                                  variant="danger"
                                  style={{
                                    fontSize: '10px',
                                    padding: '3px 5px',
                                  }}
                                  onClick={() => {
                                    setUploadFiles((last) => last.filter((_, i) => i !== index));
                                  }}
                                >
                                  <FontAwesomeIcon className="fa-times" />
                                </Button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {!!fileRejections?.length && (
                        <div>
                          <b style={{ color: colors.red }}>
                            The following file(s) will not be uploaded:
                          </b>
                          {fileRejections.map(({ file, errors }) => (
                            <li key={uuid()} className="flex">
                              <span>{file.name}</span>
                              <span style={{ color: colors.red }}>
                                {` (${errors[0].message})`}
                              </span>
                            </li>
                          ))}
                        </div>
                      )}
                    </section>
                  )}
                </Dropzone>
              </Col>
            </Row>
          )}
          <Stack direction="horizontal" gap={2}>
            <Button
              variant="secondary"
              type="submit"
              disabled={formState.isSubmitting}
            >
              Submit
            </Button>
            <Button
              type="reset"
              variant="outline-secondary"
              onClick={() => goBack()}
            >
              Cancel
            </Button>
          </Stack>
          {formState.isSubmitting && (
            <div
              style={{
                backgroundColor: 'rgba(100, 100, 100, 0.3)',
              }}
              className="position-absolute top-0 start-0 m-0 w-100 h-100 px-5 pt-3"
            >
              <ProgressBar
                animated
                variant="secondary"
                now={uploadPercent}
                label={`${Math.ceil(uploadPercent)}%`}
              />
            </div>
          )}
        </Container>
      </Form>
    </SectionWrapper>
  );
};

export default UploadAddPage;
