import React from 'react';

export const allowedMigrationNames = [
  'BCQP_DEMOGRAPHIC',
  'BCQP_INJURY',
  'BCQP_HOSPITAL',
  'BCQP_OUTCOME',
  'BCQP_LB_INITCHART_MAIN',
  'BCQP_LB_SUBSCHART_MAIN',
  'BCQP_COMORB',
  'BCQP_COMPLICATIONS',
  'BCQP_COMPS_ADD',
  'BCQP_DIAGNOSES',
  'BCQP_DIAGNOSES_NONBURN',
  'BCQP_DRUGSCREEN',
  'BCQP_FLUID_PREHOSP',
  'BCQP_FLUID_INHOSP',
  'BCQP_FLUID_BQIP',
  'BCQP_INHALDXMETHOD',
  'BCQP_PROCEDURES',
  'BCQP_RESOURCE',
  'BCQP_LB_INITCHART_DETAIL',
  'BCQP_LB_SUBSCHART_DETAIL',
  'BCQP_RELADMIT',
  'BCQP_RELADMITCOMORB',
  'BCQP_RELADMITDX',
  'BCQP_RELADMITPROCS',
  'BCQP_BQIP',
  'BCQP_CUSTOM',
];

export const allowedExt = {
  'text/xml': ['.xml'],
  'application/octet-stream': ['.xfr'],
  'text/csv': ['.csv'],
};
