import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import moment from 'moment';

import { date2UTCFormat, utcString2Date } from 'utils/dateTime';

import DatePicker from './DatePicker';

const ControlledDatePicker = ({ name, control, ...props }) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value } }) => (
      <DatePicker
        {...props}
        value={utcString2Date(value)}
        onChange={(date) => {
          const initialDateStr = date
            ? date2UTCFormat(
              moment(date)
                .set({
                  hour: 12,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
                .toDate(),
            )
            : null;

          onChange(initialDateStr);
        }}
      />
    )}
  />
);

ControlledDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
};

export default ControlledDatePicker;
