import moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const date2UTCFormat = (date) => {
  if (!date) return null;
  const result = moment(date).utc().format();
  return result;
};

export const formatUTCDate = (dateStr, format = DATE_FORMAT) => {
  if (!dateStr) return null;
  return moment.utc(dateStr).format(format);
};

export const utcString2Date = (dateStr) => {
  if (!dateStr) return null;
  return moment(dateStr).toDate();
};

export const formatDate = (date, format = DATE_FORMAT) => {
  if (!date) return '-';
  return moment(date).format(format);
};
