import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { Alert, Stack } from 'react-bootstrap';
import { MdFilterAlt, MdFilterAltOff } from 'react-icons/md';

import api from 'api';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';

import {
  getStateLabel,
  getStatusLabel,
  getProgramTypeLabel,
  programSubTypeLabel,
} from 'helpers/forms';
import { formatUTCDate } from 'utils/dateTime';

import SVButton from 'components/elements/SVButton';
import Table from 'components/elements/Table';
import SectionWrapper from 'components/layout/SectionWrapper';
import SiteVisitCalendar from 'components/forms/SiteVisitCalendar';
import AdminVerificationMenu from 'components/templates/menus/AdminVerificationMenu';
import { FlexDivSpaceBetweenCenter } from 'resources/styles/display';

import BurnCenterFilter from 'components/elements/BurnCenterFilter';
import ExtraFilters from 'pages/verification/admin/filters/ExtraFilters';

import FilterEnum from './filters/data';
import ExportListButton from './ExportListButton';

const initFilters = Object.values(FilterEnum).reduce(
  (res, key) => ({ ...res, [key]: null }),
  {},
);

const columns = [
  {
    id: 'bcqpBurnCenterName',
    label: 'Site Name',
    headerStyle: { width: '40%' },
  },
  {
    id: 'programType',
    label: 'Program Type',
    override: ({ programTypeId, programSubtypeId }) => `${getProgramTypeLabel(programTypeId)}/${programSubTypeLabel(
      programSubtypeId,
    )}`,
  },
  {
    id: 'state',
    label: 'Review Status',
    override: ({ stateId }) => getStateLabel(stateId),
  },
  {
    id: 'siteVisitAt',
    label: 'Site Visit Date',
    override: ({ siteVisitAt }) => formatUTCDate(siteVisitAt),
  },
  {
    id: 'status',
    label: 'Final Determination',
    override: ({ statusId }) => getStatusLabel(statusId),
  },
  {
    id: 'expiredAt',
    label: 'Expiration Date',
    override: ({ expiredAt }) => formatUTCDate(expiredAt),
  },
];

const VerificationList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showExtraFilters, setShowExtraFilters] = React.useState(false);

  const {
    isLoading,
    error,
    doRequest: getListData,
    data: listData,
  } = useRequest(api.getAdminOverview);

  const {
    page,
    handlePageChange,
    sortBy,
    sortOrder,
    handleSortChange,
    filters,
    handleFilterChange,
    handleFiltersReplace,
  } = useTableState(columns[0].id, 'asc', initFilters);

  const requestParams = React.useMemo(
    () => ({
      order: sortBy,
      orderDirection: sortOrder,
      ...filters,
    }),
    [sortBy, sortOrder, filters],
  );

  const fetchListData = React.useCallback(
    () => getListData({
      ...requestParams,
      Page: page,
    }),
    [getListData, page, requestParams],
  );

  const toggleFiters = React.useCallback(() => {
    if (showExtraFilters) {
      handleFiltersReplace({
        ...initFilters,
        [FilterEnum.burncenter]: filters[FilterEnum.burncenter],
      });
    }
    setShowExtraFilters(!showExtraFilters);
  }, [filters, showExtraFilters, handleFiltersReplace]);

  React.useEffect(() => {
    fetchListData();
  }, [fetchListData]);

  return (
    <SectionWrapper title="Verification Administrator Dashboard">
      <Stack
        direction="horizontal"
        gap={3}
        className="flex-grow-1 align-items-start"
      >
        <Stack gap={5} style={{ maxWidth: '15.6rem' }}>
          <AdminVerificationMenu />
          <SiteVisitCalendar />
        </Stack>
        <div className="d-flex flex-grow-1 flex-column">
          <FlexDivSpaceBetweenCenter className="pb-2">
            <SVButton to="new" as={Link} size="sm" variant="secondary">
              New Verification
            </SVButton>
            <Stack direction="horizontal" gap={2}>
              <div style={{ width: '400px' }}>
                <BurnCenterFilter
                  value={filters.bcqpBurnCenterId}
                  onChange={(value) => handleFilterChange('bcqpBurnCenterId', value)}
                />
              </div>
              <SVButton size="sm" variant="secondary" onClick={toggleFiters}>
                {showExtraFilters ? <MdFilterAltOff /> : <MdFilterAlt />}
              </SVButton>
              <ExportListButton requestParams={requestParams} />
            </Stack>
          </FlexDivSpaceBetweenCenter>
          {showExtraFilters && (
            <ExtraFilters
              filters={filters}
              onFilter={handleFilterChange}
              onClearFilter={() => handleFiltersReplace(initFilters)}
            />
          )}
          {error ? (
            <Alert variant="danger">Error Loading List</Alert>
          ) : (
            <Table
              columns={columns}
              items={listData?.items || []}
              handlePageChange={handlePageChange}
              handleOrderChange={handleSortChange}
              order={sortBy}
              orderDirection={sortOrder}
              pagination={{
                total: listData?.total || 0,
                limit: listData?.limit || 10,
                page,
              }}
              onClickRow={({ id }) => navigate(`${pathname}/${id}`)}
              isLoading={isLoading}
            />
          )}
        </div>
      </Stack>
    </SectionWrapper>
  );
};

export default VerificationList;
