export const appRoles = {
  abaADMIN: 'ABA Admin',
  bdataADMIN: 'BData Administrator',
  qualityAssurance: 'Quality Assurance',
  qualityImprovement: 'Quality Improvement',
  registrar: 'Registrar',
  powerUser: 'Power User',
  superUser: 'Super User',
  reportViewer: 'Report Viewer',
  summaryReportViewer: 'Summary Report Viewer',
  manager: 'Manager',
  siteReviewer: 'Site Reviewer',
  verification: 'Verification',
};

export const Roles = {
  manager: 'manager',
  abaAdmin: 'aba_admin',
  siteReviewer: 'site_reviewer',
  bdataAdmin: 'bdata_administrator',
  burnCenterUser: 'burn_center_user',
};

export const ReviewStatusLabels = {
  NotStarted: 'Not Started',
  Requested: 'Requested',
  Started: 'Started',
  PRQLocked: 'PRQ Locked',
  ExecutiveSessionLocked: 'Meetings & Rounds Locked',
  ReviewedBySR: 'Under Committee Review',
  Concluded: 'Concluded',
  Closed: 'Closed',
};

export const formState = {
  NOT_STARTED: 1,
  REQUESTED: 2,
  STARTED: 3,
  PRQ_LOCKED: 4,
  ES_LOCKED: 5,
  UNDER_REVIEW: 6,
  CONCLUDED: 7,
  CLOSED: 8,
};

export const formStateLabels = {
  [formState.NOT_STARTED]: ReviewStatusLabels.NotStarted,
  [formState.REQUESTED]: ReviewStatusLabels.Requested,
  [formState.STARTED]: ReviewStatusLabels.Started,
  [formState.PRQ_LOCKED]: ReviewStatusLabels.PRQLocked,
  [formState.ES_LOCKED]: ReviewStatusLabels.ExecutiveSessionLocked,
  [formState.UNDER_REVIEW]: ReviewStatusLabels.ReviewedBySR,
  [formState.CONCLUDED]: ReviewStatusLabels.Concluded,
  [formState.CLOSED]: ReviewStatusLabels.Closed,
};

export const formStatus = {
  RECOMMEND_VERIFICATION: 1,
  RECOMMEND_DEFER_VERIFICATION: 2,
  VERIFICATION: 3,
  NON_VERIFICATION: 4,
  NON_VERIFICATION_PENDING: 5,
};

export const statusLabels = {
  [formStatus.RECOMMEND_VERIFICATION]: 'Recommend Verification',
  [formStatus.RECOMMEND_DEFER_VERIFICATION]: 'Recommend Defer Verification',
  [formStatus.VERIFICATION]: 'Verification',
  [formStatus.NON_VERIFICATION]: 'Non Verification',
  [formStatus.NON_VERIFICATION_PENDING]: 'Non Verification Pending Review',
};

export const ReportingRoleEnum = {
  DASHBOARD: 'Dashboard Viewer',
  ALL_REPORTS: 'All Reports Viewer',
  EXPLORER: 'Explorer',
};

export const BurncenterAccessLevelEnum = {
  FULL_PLATFORM: 'Full Platform',
};

export const ProgramTypeLabels = {
  BurnCenter: 'Burn Center',
  Fellowship: 'Fellowship',
  FocusReview: 'Focus Review',
};

const DataUploadBaseLabels = {
  CallForData2025: {
    long: 'Current Call for Data XML/XFR - 2025 Call for Data (Admissions from Jan 1, 2024 - Dec 31, 2024)',
    short: '2025 Call for Data',
  },
  CallForData2024: {
    long: '2024 Call for Data XML/XFR - 2024 Call for Data (Admissions from Jan 1, 2023 - Dec 31, 2023)',
    short: '2024 Call for Data',
  },
  CallForData2023: {
    long: '2023 Call for Data (Admissions from Jan 1, 2022 - Dec 31, 2022)',
    short: '2023 Call for Data',
  },
  CallForDataOther: {
    long: 'Other Call for Data XML/XFR - Prior Years or Resubmittal',
    short: 'Other Call for Data',
  },
  DataMigration: {
    long: 'Historical data migration',
    short: 'Data Migration',
  },
};

export const DataUploadLabels = Object.fromEntries(
  Object.entries(DataUploadBaseLabels).map(([key, value]) => [key, value.long]),
);

export const DataUploadShortLabels = Object.fromEntries(
  Object.entries(DataUploadBaseLabels).map(([key, value]) => [
    key,
    value.short,
  ]),
);

export const DataUploadOptions = Object.entries(DataUploadLabels)
  .filter(([key]) => !['CallForData2023', 'CallForData2024'].includes(key))
  .map(([value, label]) => ({ value, label }));

export const ContentCategoryLabels = {
  GettingStarted: 'Getting Started',
  DataEntry: 'Data Entry',
  CallForData: 'Call for Data',
  Reporting: 'Reporting',
  Verification: 'Verification',
  QiPiFeatures: 'QI/PI Features',
  UserManagement: 'User Management',
  SecurityCompliance: 'Security/Compliance',
  DataDictionary: 'Data Dictionary',
  All: 'All',
};
